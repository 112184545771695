@import "./themes/generated/variables.base.scss";

.app {
  background-color: white;
  display: flex;
  height: 100%;
  width: 100%;

  .content {
    line-height: 1.5;

    h2 {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .content-block {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;

    .screen-x-small & {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .dx-form-group-with-caption {
    padding-top: 20px;
  }

  .dx-button {
    height: unset;
    max-height: unset;

    &.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
      height: 28px;
      width: 28px;
      max-height: 28px;
      max-width: 28px;
    }
  }

  .dx-button-has-text .dx-button-content {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
}

html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover .dx-texteditor-input {
  color: unset;
}

.dx-checkbox.dx-checkbox-checked.dx-state-disabled .dx-checkbox-icon,
.dx-checkbox.dx-checkbox-checked.dx-state-readonly .dx-checkbox-icon,
.dx-checkbox.dx-checkbox-indeterminate.dx-state-disabled .dx-checkbox-icon,
.dx-checkbox.dx-checkbox-indeterminate.dx-state-readonly .dx-checkbox-icon {
  background-color: unset;
}