.staff-list-component {
  .item-container {
    display: flex;

    .memberId {
      display: none;
    }
  }

  .staff-list-header {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom: 10px;

    .school-info-container {
      flex: auto;
    }

    .action-button-container {
      flex: 0 0 40px;
    }
  }

  #memberList .dx-list-select-all {
    display: none;
  }
}

div.dx-actionsheet-item div.dx-button {
  height: unset;

  div.dx-button-content {
    white-space: unset;
    text-overflow: unset;
  }
}

.home-email-badge-container{
  position:relative;
}

.home-email-badge-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 5px; 
  background: green;
}

.home-email-badge-text-container-red {
  background: red;
}

.home-email-badge-text-container-green {
  background: green;
}

.home-email-badge-text {
  font-weight:bold; 
  color: white;
}

.home-email-container-list {
  text-align: left;
  display: flex;
}

.home-email-badge-text-container-list {
  width: 25%;
  margin: 10px;
}

.home-email-badge-text-list {
  color: white;
  display: table;
  margin: 0 auto;
  padding: 5px;
}
