$card-container-background-color: rgba(255, 255, 255, 0.288);

.card-container {
  // background-color: $card-container-background-color;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  min-width: 290px;
  overflow: auto;
  align-items: flex-start;
}

.add-new-sa-container {
  width: 100%;
  text-align: center;
  padding-top: 15px;
}

