.item-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .push-to-right {
    margin-left: auto;
    text-align: left;
  }
}

.send-button {
  margin-top: 20px;
}
