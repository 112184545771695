@import "../../themes/generated/variables.base.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }

  .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 40px;

    .screen-x-small & {
      padding: 0 20px;
    }
  }

  #headerTitle {
    padding: 0;
    margin: 0;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .screen-x-small & {
      max-width: 270px;
    }
  }
}

