@import "../../themes/generated/variables.base.scss";

.single-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .layout-header {
    z-index: 1501;
  }

  .dx-scrollable-content {
    min-height: 100%!important;
  }

  #singleCardScrollView {
    flex-grow: 1;
    width: 330px;
    margin: auto auto;
    text-align: center;

    .dx-form {
      text-align: left;
    }

    .title {
      color: $base-accent;
      font-weight: bold;
      font-size: 15px;
      margin: 0px;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .screen-x-small &,
    .screen-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .content {
      width: 100%;
      height: 100%;
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 0px;

      .screen-x-small &,
      .screen-small & {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
      }

      .responsive-paddings {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;

        .screen-x-small &,
        .screen-small & {
          padding-top: 10px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 10px;
        }
      }
    }

    .dx-button.dx-button-has-text {
      height: 100%;
      margin-top: 20px;
      width: 100%;
      max-width: 440px;
      white-space: normal;

      &:first-of-type {
        margin-top: 0px;
      }
    }
  }
}
