$border-color: RGB(117, 189, 67);
$card-background-color: RGB(245, 250, 247);
$card-header-background-color: RGB(0, 186, 207);
$card-header-title-color: black;
$card-header-subtitle-color: white;
$card-editor-background-color: white;
$card-button-text-color: $border-color;
$card-label-color: rgba(0, 0, 0, 0.35);

.card {
  border: 1px solid $border-color;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: $card-background-color;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 400px;
  flex-basis: 100%;
  min-width: 290px;
  align-self: stretch;
  flex: 0 0 100%;
}

.card-header {
  background-color: $card-header-background-color;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    margin: 0;
    padding-left: 10px;
    padding: 5px;
    font-size: 22px !important;
    color: $card-header-title-color;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
  }

  h2 {
    margin: 0 !important;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 0;
    font-size: 15px !important;
    color: $card-header-subtitle-color;
    font-weight: normal;
    text-transform: uppercase;
  }
}

.make-main-popup-body,
.end-date-popup-body,
.start-date,
.end-date,
.division {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  :global(.dx-texteditor.dx-editor-filled) {
    background-color: $card-editor-background-color;
    border-bottom: 1px solid $border-color;
    padding-top: 5px;
  }
  :global(.dx-texteditor.dx-editor-filled.dx-state-readonly) {
    border: none;
  }
  :global(.dx-texteditor.dx-editor-filled.dx-state-disabled),
  :global(.dx-texteditor.dx-editor-filled.dx-state-readonly),
  :global(.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover) {
    background-color: $card-background-color !important;
  }
  :global(.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input) {
    color: black;
    font-size: 15px;
  }
  :global(.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover::after) {
    border: none;
  }

  :global(.dx-texteditor-label) {
    position: absolute;
    top: 0px;
    left: 5px;
    color: $card-label-color;
  }
  :global(.dx-dropdowneditor-icon::before) {
    color: $border-color;
  }
}

.end-position-button,
.make-main-button {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  color: $card-button-text-color;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    font-weight: 700;
  }
  text-align-last:left;
}

.card-body {
  padding: 15px;
}

.make-main-popup-wrapper,
.end-date-popup-wrapper {
  max-width: '400px';
}

.make-main-popup-content,
.end-date-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.make-main-popup-body,
.end-date-popup-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
}

.make-main-popup-footer,
.end-date-popup-footer {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-top: 15px;
}
